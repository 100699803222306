import { FC, useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import classes from './Signup.module.css';
import { Modal } from '../../components/UI/Modal/Modal';
import { ConfirmationModal } from '../../components/UI/ConfirmationModal/ConfirmationModal';
import { useModal } from '../../hooks/useModal';
import SignupForm from '../../components/SignupForm/SignupForm';
import { Footer } from '../../components/UI/Footer/Footer';

const TIMEOUT_ACTIVITY = 10000;

const Signup: FC = () => {
  const [stopActivity, setStopActivity] = useState(false);
  const [clickedYes, setClickedYes] = useState(false);
  const { isShown, toggle } = useModal();
  const navigate = useNavigate();

  const onConfirm = () => {
    toggle();
    setClickedYes(true);
  };
  const onCancel = () => {
    navigate('/');
  };

  /* Iddle stuff */
  const handleActivity = () => {
    toggle(); // show pop up after 2 seconds of inactivity
  };

  useIdleTimer({
    timeout: 60000, // Timeout checking hook for user inactivity
    onIdle: handleActivity, // function to handle user inactivity after 15 seconds.
    debounce: 500,
  });
  /* End iddle stuff */

  // Hook to validate user activity after 10 seconds that modal was shown
  useEffect(() => {
    let timeout: any;
    if (isShown && !stopActivity && !clickedYes) {
      timeout = setTimeout(() => {
        setStopActivity(true);
        clearTimeout(timeout);
      }, TIMEOUT_ACTIVITY);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isShown, stopActivity, clickedYes]);

  // Hook to validate user activity after 10 seconds that modal was shown and after user clicked YES
  useEffect(() => {
    if (isShown && clickedYes) {
      setTimeout(() => {
        setStopActivity(true);
      }, TIMEOUT_ACTIVITY);
    }
  }, [isShown, clickedYes]);

  // Hook to return to Home page after 25 seconds of user inactivity
  useEffect(() => {
    if (stopActivity) {
      navigate('/');
    }
  }, [stopActivity, clickedYes]);

  return (
    <div className={classes.container}>
      <SignupForm />
      <Footer />
      <Modal
        isShown={isShown}
        hide={toggle}
        headerText="Confirmation"
        modalContent={
          <ConfirmationModal
            onConfirm={onConfirm}
            onCancel={onCancel}
            message="Do you want to complete your registration?"
          />
        }
      />
    </div>
  );
};

export default Signup;
