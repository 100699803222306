/* eslint-disable @typescript-eslint/naming-convention */
import axios from 'axios';
import { GET_SALESREP, POST_ATTENDEE } from '../utils/endpoints';
import FormInputs, { Salesrep, ResponseSalesrep } from '../utils/types';

export const registerAttendee = async (data: FormInputs): Promise<number> => {
  const {
    first_name,
    last_name,
    company,
    email,
    job_title,
    phone,
    salesrep,
    salutation,
    consent_information,
    consent_emails,
  } = data;

  const res = await axios.post(POST_ATTENDEE, {
    first_name,
    last_name,
    company,
    email,
    job_title,
    phone,
    salesrep,
    salutation,
    consent_information,
    consent_emails,
  });

  return res.data.statusCode;
};

export const getSalesrep = async (): Promise<Salesrep[]> => {
  const { data } = await axios.get<ResponseSalesrep>(GET_SALESREP);
  if (data) {
    const basfRep: any = data.data.find((saleRep) => saleRep.id === 98);
    const sortedData = data.data
      .sort((a: any, b: any) =>
        // eslint-disable-next-line no-nested-ternary
        a.last_name > b.last_name ? 1 : b.last_name > a.last_name ? -1 : 0,
      )
      .filter((saleRep) => saleRep.id !== 98);

    sortedData.unshift(basfRep);

    return sortedData;
  }

  throw Error(data);
};
