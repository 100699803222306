import styled from 'styled-components';

export const Wrapper = styled.div`
  width 80%;
  @media(min-width: 768px) {
  width: 60%;
  }
  @media(min-width: 1024px) {
  width: 40%;
  }
`;

export const ConfirmationButtons = styled.div`
  display: flex;
  justify-content: center;
`;

export const Message = styled.div`
  font-size: 1rem;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
`;

export const YesButton = styled.button`
  width: 6rem;
  background-color: #22a0d3;
  border: 1px solid #004a96;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
`;

export const NoButton = styled.button`
  width: 6rem;
  margin-left: 10px;
  background-color: #c5c6c6;
  border: 1px solid #004a96;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
`;
