import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../Home/Home';
import Signup from '../Signup/Signup';
import Thankyou from '../Thankyou/Thankyou';

export const Router: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="*" element={<Home />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/thankyou" element={<Thankyou />} />
    </Routes>
  </BrowserRouter>
);
