import React, { FunctionComponent } from 'react';
import {
  ConfirmationButtons,
  Message,
  YesButton,
  NoButton,
} from './ConfirmationModal.style';

interface ConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
}

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  message,
  onCancel,
  onConfirm,
}) => {
  return (
    <>
      <Message>{message}</Message>
      <ConfirmationButtons>
        <YesButton onClick={onConfirm}>Yes</YesButton>
        <NoButton onClick={onCancel}>No</NoButton>
      </ConfirmationButtons>
    </>
  );
};
