import { useEffect, useState } from 'react';

export const useBrowser = () => {
  const [browser, setBrowser] = useState<string>();

  useEffect(() => {
    function test(regexp: RegExp) {
      return regexp.test(window.navigator.userAgent);
    }
    const result = () => {
      switch (true) {
        case test(/edg/i):
          return 'Microsoft Edge';
        case test(/trident/i):
          return 'Microsoft Internet Explorer';
        case test(/firefox|fxios/i):
          return 'Mozilla Firefox';
        case test(/opr\//i):
          return 'Opera';
        case test(/ucbrowser/i):
          return 'UC Browser';
        case test(/samsungbrowser/i):
          return 'Samsung Browser';
        case test(/chrome|chromium|crios/i):
          return 'Google Chrome';
        case test(/safari/i):
          return 'Apple Safari';
        default:
          return 'Other';
      }
    };
    setBrowser(result());
  }, []);

  return browser;
};
