import classes from './Footer.module.css';
import imgFooter from '../../../assets/footer.png';

export const Footer = () => {
  return (
    <div>
      <img src={imgFooter} alt="BASF footer" className={classes.footer} />
    </div>
  );
};
