import { FC } from 'react';
import { Link } from 'react-router-dom';
import classes from './Home.module.css';
import { Footer } from '../../components/UI/Footer/Footer';
import imgWelcome from '../../assets/Welcome.png';
import imgRegister from '../../assets/Register_Button_Orange.png';

const Home: FC = () => (
  <div className={classes.container}>
    <div className={classes.content}>
      <div className={classes.title}>
        <img src={imgWelcome} alt="thankyou text" className={classes.welcome} />
      </div>
      <div>
        <Link to="/signup">
          <img
            src={imgRegister}
            alt="thankyou text"
            className={classes.register}
          />
        </Link>
      </div>
      <Footer />
    </div>
  </div>
);

export default Home;
