import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useNavigate } from 'react-router-dom';
import { getSalesrep, registerAttendee } from '../../services/signup.service';
import FormInputs, { Salesrep } from '../../utils/types';
import classes from './SignupForm.module.css';
import { useBrowser } from '../../hooks/useBrowser';

const SignupForm = () => {
  const [salesrep, setSalesrep] = useState<Salesrep[]>();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { isValid, errors, touchedFields },
  } = useForm<FormInputs | any>({
    mode: 'onChange',
  });
  const browser = useBrowser();
  const onSubmit = async (data: FormInputs) => {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    data.consent_emails = data.consent_emails === 'true';
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    data.consent_information = data.consent_information === 'true';

    const response = await registerAttendee(data);

    if (response === 201) {
      navigate('/thankyou');
    }
  };

  useEffect(() => {
    const salesrepList = async () => {
      const response = await getSalesrep();
      setSalesrep(response);
    };
    salesrepList();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.gridContainer}>
            <label className={classes.salutation} htmlFor="salutation">
              Salutation
              <select
                id="salutation"
                className={
                  errors.salutation
                    ? classes.inputDropdown
                    : classes.fieldDropdown
                }
                {...register('salutation', {
                  required: true,
                  onBlur: (e) => {
                    if (
                      !errors.salutation &&
                      !touchedFields.salutation &&
                      e.target.value === ''
                    ) {
                      setError('salutation', {
                        type: 'required',
                      });
                    }
                  },
                })}
              >
                <option value="">Drop Down</option>
                <option value="mr">Mr.</option>
                <option value="miss">Miss</option>
                <option value="ms">Ms.</option>
                <option value="mrs">Mrs.</option>
                <option value="mx">Mx.</option>
                <option value="dr">Dr.</option>
              </select>
            </label>
            <label
              className={classes.firstname}
              id="first_name"
              htmlFor="first_name"
            >
              First Name
              <input
                type="text"
                id="first_name"
                maxLength={20}
                className={`${classes.firstnameInput} ${
                  errors.first_name ? classes.input : classes.field
                }`}
                {...(browser === 'Microsoft Edge'
                  ? {
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                    }
                  : {
                      autoComplete: 'off',
                    })}
                placeholder="Tap to Fill in Text"
                {...register('first_name', {
                  required: true,
                  onBlur: (e) => {
                    if (
                      !errors.first_name &&
                      !touchedFields.first_name &&
                      e.target.value === ''
                    ) {
                      setError('first_name', {
                        type: 'required',
                      });
                    }
                  },
                })}
              />
            </label>
            <label
              className={classes.lastname}
              id="last_name"
              htmlFor="last_name"
            >
              Last Name
              <input
                type="text"
                id="last_name"
                maxLength={20}
                className={`${classes.lastnameInput} ${
                  errors.last_name ? classes.input : classes.field
                }`}
                placeholder="Fill in Text"
                {...(browser === 'Microsoft Edge'
                  ? {
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                    }
                  : {
                      autoComplete: 'off',
                    })}
                {...register('last_name', {
                  required: true,
                  onBlur: (e) => {
                    if (
                      !errors.last_name &&
                      !touchedFields.last_name &&
                      e.target.value === ''
                    ) {
                      setError('last_name', {
                        type: 'required',
                      });
                    }
                  },
                })}
              />
            </label>
            <label
              className={classes.jobTitle}
              id="job_title"
              htmlFor="job_title"
            >
              Job Title
              <input
                type="text"
                id="job_title"
                maxLength={40}
                className={`${classes.jobTitleInput} ${
                  errors.job_title ? classes.input : classes.field
                }`}
                {...(browser === 'Microsoft Edge'
                  ? {
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                    }
                  : {
                      autoComplete: 'off',
                    })}
                placeholder="Fill in Text"
                {...register('job_title', {
                  required: true,
                  onBlur: (e) => {
                    if (
                      !errors.job_title &&
                      !touchedFields.job_title &&
                      e.target.value === ''
                    ) {
                      setError('job_title', {
                        type: 'required',
                      });
                    }
                  },
                })}
              />
            </label>
            <label className={classes.company} htmlFor="company">
              Company
              <input
                type="text"
                id="company"
                maxLength={30}
                {...(browser === 'Microsoft Edge'
                  ? {
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                    }
                  : {
                      autoComplete: 'off',
                    })}
                className={errors.company ? classes.input : classes.field}
                placeholder="Fill in Text"
                {...register('company', {
                  required: true,
                  onBlur: (e) => {
                    if (
                      !errors.company &&
                      !touchedFields.company &&
                      e.target.value === ''
                    ) {
                      setError('company', {
                        type: 'required',
                      });
                    }
                  },
                })}
              />
            </label>
            <label className={classes.salesRep} htmlFor="salesrep">
              Please select your BASF Sales Rep:
              <select
                id="salesrep"
                className={
                  errors.salesrep
                    ? classes.inputDropdown
                    : classes.fieldDropdown
                }
                {...(browser === 'Microsoft Edge'
                  ? {
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                    }
                  : {
                      autoComplete: 'off',
                    })}
                {...register('salesrep', {
                  required: true,
                  onBlur: (e) => {
                    if (
                      !errors.salesrep &&
                      !touchedFields.salesrep &&
                      e.target.value === ''
                    ) {
                      setError('salesrep', {
                        type: 'required',
                      });
                    }
                  },
                })}
              >
                <option value="">Drop Down</option>
                {salesrep?.map((rep) => (
                  <option value={rep.id}>
                    {rep.last_name}, {rep.first_name}
                  </option>
                ))}
              </select>
            </label>
            <label className={classes.phone} id="phone" htmlFor="phone">
              Phone
              <input
                type="text"
                id="phone"
                maxLength={25}
                className={`${classes.phoneInput} ${
                  errors.phone ? classes.input : classes.field
                }`}
                {...(browser === 'Microsoft Edge'
                  ? {
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                    }
                  : {
                      autoComplete: 'off',
                    })}
                placeholder="Fill in Text"
                {...register('phone', {
                  required: true,
                  onBlur: (e) => {
                    if (
                      !errors.phone &&
                      !touchedFields.phone &&
                      e.target.value === ''
                    ) {
                      setError('phone', {
                        type: 'required',
                      });
                    }
                  },
                })}
              />
            </label>
            <label className={classes.email} htmlFor="email">
              Email
              <span>
                <input
                  id="email"
                  type="email"
                  maxLength={50}
                  className={`${classes.emailInput} ${
                    errors.email ? classes.input : classes.field
                  }`}
                  {...(browser === 'Microsoft Edge'
                    ? {
                        autoComplete: 'off',
                        'aria-autocomplete': 'none',
                      }
                    : {
                        autoComplete: 'off',
                      })}
                  placeholder="Fill in Text"
                  {...register('email', {
                    required: true,
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'invalid email address',
                    },
                    onBlur: (e) => {
                      if (
                        !errors.email &&
                        !touchedFields.email &&
                        e.target.value === ''
                      ) {
                        setError('email', {
                          type: 'required',
                        });
                      }
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  as="p"
                  className={classes.errorMessage}
                />
              </span>
            </label>
            <div className={classes.consentsinfo}>
              <label htmlFor="consent_information">
                I give consent for BASF Corporation to use my contact
                information (name and email address).
                <span>
                  <input
                    type="radio"
                    id="consent_information_yes"
                    value="true"
                    className={
                      errors.consent_information
                        ? classes.fieldRadio
                        : classes.inputRadio
                    }
                    {...register('consent_information', {
                      required: true,
                    })}
                  />
                  YES
                </span>
                <span>
                  <input
                    className={classes.inputRadio}
                    type="radio"
                    id="consent_information_no"
                    value="false"
                    {...register('consent_information', {
                      required: true,
                    })}
                  />
                  NO
                </span>
              </label>
            </div>
            <div className={classes.consentsemail}>
              <label htmlFor="consent_emails">
                I give consent to receive electronic mail about products or
                services from BASF Corporation.
                <span>
                  <input
                    className={classes.inputRadio}
                    type="radio"
                    id="consent_emails_yes"
                    value="true"
                    {...register('consent_emails', {
                      required: true,
                    })}
                  />
                  YES
                </span>
                <span>
                  <input
                    className={classes.inputRadio}
                    type="radio"
                    id="consent_emails_no"
                    value="false"
                    {...register('consent_emails', {
                      required: true,
                    })}
                  />
                  NO
                </span>
              </label>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <div className={classes.registerContainer}>
              {!isValid && (
                <span className={classes.errorForm}>
                  Please complete all fields to enable registration
                </span>
              )}
              <button
                type="submit"
                disabled={!isValid}
                className={classes.registerButton}
              >
                REGISTER
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupForm;
