import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './Thankyou.module.css';
import { Footer } from '../../components/UI/Footer/Footer';
import imgThankyou from '../../assets/thankYouText.png';

const Thankyou: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 5000); // redirecting to home after 5 seconds
  });
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <img
          src={imgThankyou}
          alt="thankyou text"
          className={classes.thankyou}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Thankyou;
